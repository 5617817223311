import { memo, useCallback, useMemo, useSyncExternalStore } from 'react';
import { useLocation } from 'react-router-dom';
import { assetsClipUpdate } from '@goldcast/api/content';
import ClipPublishStatusBadge from './ClipPublish/ClipPublishStatusBadge';
import ClipPublishButton from './ClipPublish/ClipPublishButton';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import ClipUndoRedo from '@/components/molecules/ClipUndoRedo';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import ClipSharePopup from '@/components/molecules/SharePopup/ClipSharePopup/ClipSharePopup';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import useSavedSearch from '@/hooks/useSavedSearch';
import ContentSettings from '@/components/molecules/ContentSettings';
import SavedSearchTitlePopup from '@/Pages/SavedSearch/SavedSearchTitlePopup';
import SessionTitlePopup from '@/Pages/SessionDetail/SessionTitlePopup';
import NewTemplateButton from '@/Pages/Clip/SideBar/TemplatesSideBar/NewTemplateButton';
import { core } from '@/stores/core';
import { useAppContext } from '@/context/AppContext/AppContext';

function ClipHeader() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const { adminAppStore } = useAppContext();
  const { clipData } = useClipsContext();

  const location = useLocation();
  const { isSavedSearchPage } = useSavedSearch();

  const isRecordingsPage = useMemo(() => {
    return location.pathname.includes('/recordings');
  }, [location.pathname]);

  const isClipListPage = useMemo(() => {
    return location.pathname.includes('/clips');
  }, [location.pathname]);

  const isEventRecordingEnabled = useMemo(
    () => adminAppStore.getters?.['org/getCurrentOrg']?.showVideoLibraryEventRecording || false,
    [adminAppStore]
  );

  const isVideoLibraryClipPublishEnabled = useMemo(
    () => adminAppStore.getters?.['org/getCurrentOrg']?.showVideoLibraryClipPublish || false,
    [adminAppStore]
  );

  const isRecordingStudioEnabled = useMemo(
    () => adminAppStore.getters?.['org/getCurrentOrg']?.isRecordingStudioEnabled || false,
    [adminAppStore]
  );

  const updateRecordingTitle = useCallback(() => {
    const title = core.getSnapshot().content?.title;
    if (isRecordingsPage && title) {
      assetsClipUpdate({
        id: clipData.id,
        body: {
          ...clipData,
          title,
          asset_metadata: {
            ...clipData.asset_metadata,
            config: {
              ...clipData.asset_metadata.config,
              filename: title
            }
          }
        }
      });
    }
  }, [clipData, isRecordingsPage]);

  const isPublishingAllowed = useMemo(() => {
    if (adminAppStore.user?.is_content_lab_standalone) {
      return false;
    }

    // For clip page
    if (isVideoLibraryClipPublishEnabled && isClipListPage) {
      return true;
    }

    if (!isRecordingsPage) {
      return false;
    }

    // For recording studio recordings
    if (isRecordingStudioEnabled && coreStore.content?.event_type === 'Recording') {
      return true;
    }

    // For event recordings
    if (isEventRecordingEnabled && coreStore.content?.media_source_type === 'RECORDING') {
      return true;
    }

    return false;
  }, [
    adminAppStore.user?.is_content_lab_standalone,
    coreStore.content?.event_type,
    coreStore.content?.media_source_type,
    isEventRecordingEnabled,
    isRecordingStudioEnabled,
    isVideoLibraryClipPublishEnabled,
    isRecordingsPage,
    isClipListPage
  ]);

  return (
    <div className="relative flex w-full items-center justify-between p-4">
      <div className="flex items-center space-x-1">
        {isSavedSearchPage ? <SavedSearchTitlePopup /> : <SessionTitlePopup onUpdate={updateRecordingTitle} />}
        {isPublishingAllowed && <ClipPublishStatusBadge />}
      </div>
      <div className="flex items-center space-x-3">
        <FreeTrialUpgradeNag />
        <ClipUndoRedo isInline={true} />
        {coreStore.content?.id && <DownloadsPopup contentId={coreStore.content.id} />}
        {!isRecordingsPage && <NewTemplateButton className="bg-white" />}
        {isPublishingAllowed && <ClipPublishButton />}
        <ClipSharePopup
          iconButtonVariation={isPublishingAllowed ? 'list' : 'filled'}
          activeClipId={clipData.id}
          downloadIntent={location.state?.downloadIntent}
        />
        {isSavedSearchPage && <ContentSettings />}
      </div>
    </div>
  );
}

export default memo(ClipHeader);
