import { AbsoluteFill, Sequence, Video } from 'remotion';
import HlsVideo from '../HlsVideo';
import { VideoElement } from '@/App/remotion/MainPlayer/types/Element/VideoElement';

const REMOTION_SAFE_FRAME = 1;

const VideoElementComponent: React.FC<{
  element: VideoElement;
}> = ({ element }) => {
  const { fromFrame, toFrame, details, style, containerStyle } = element;
  const durationInFrames = Math.round(toFrame - fromFrame);

  const transformedElementStyle = {
    ...style,
    boxShadow: (style.boxShadow || [])
      .map(shadow => `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`)
      .join(',')
  };

  const transformedContainerStyle = {
    ...containerStyle,
    boxShadow: (containerStyle.boxShadow || [])
      .map(shadow => `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`)
      .join(',')
  };

  const useHls = true;
  const VideoComp = useHls ? HlsVideo : Video;

  return (
    <Sequence from={fromFrame} durationInFrames={durationInFrames} layout="none">
      <AbsoluteFill style={transformedContainerStyle}>
        <VideoComp
          startFrom={element?.fromFrame || 0}
          endAt={(element?.toFrame || 0) + REMOTION_SAFE_FRAME}
          src={details.src}
          volume={Math.round(details.volume[0].value / 100)}
          style={{ pointerEvents: 'none', ...transformedElementStyle }}
          pauseWhenBuffering
        />
      </AbsoluteFill>
    </Sequence>
  );
};

export default VideoElementComponent;
