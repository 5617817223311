import { pick } from 'radash';
import { ClipAssetResponse } from '@goldcast/api/content';
import { core, isAudioContent } from './core';
import createStore from './store';
import { updateClipConfig } from './sharedAPI/sharedAPI';
import { initHistory, updateHistory } from './clipHistory';
import featureFlagStore from './featureFlagStore';
import {
  CaptionStyle,
  ClipDeletes,
  ClipMetadata,
  type CaptionStyles,
  type CaptionsPosition,
  type Clip,
  type HighlightType,
  type LayoutType,
  type SizeType
} from '@/domains/asset';
import { debounce, isEmptyObject, rgbaToHex } from '@/libs/utils';
import { generateThumbnail } from '@/libs/thumbnail';
import { loadClip } from '@/libs/clipContentUtil';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { showErrorToast } from '@/libs/toast/toast';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';
import { propertiesToCompare } from '@/Pages/Clip/SideBar/Templates/utils';
import { getAnalyticsClipEditType, getClipAnalyticsPayload } from '@/hooks/utils/analyticsHookUtil';
import { getLayoutAvailabilityStatus } from '@/context/ClipsContext/ClipsContextUtils';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { getRemotionConfig } from '@/App/remotion/libs';
import { DEFAULT_FONT_SIZE } from '@/App/remotion/libs/caption';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export interface DeletedRange {
  next_start_time: number;
  prev_end_time: number;
  bounds: [start_time: number, end_time: number];
}

export interface EditedWord {
  word: string;
  start_time: number;
  end_time: number;
}

type CurrentClips = Record<string, Clip>;

type UpdateIntroOutroProps = {
  url?: string;
  duration?: number;
};

export const allClips = createStore<Clip[]>([]);
export const canGenerateClips = createStore<boolean>(true);
// loader for clip captions edits
export const clipCaptionsLoader = createStore<Record<string, boolean>>({});
export const templateLoader = createStore<boolean>(false);
export const clipGeneratingCount = createStore<number>(0);

/**
 * Used to store boolean flags against clip ids of a freshly loaded clip which don't have download configs.
 * The download configs are generated and applied after a clip is loaded along with its other dependencies like speaker analysis
 */
export const clipsNeedingConfigUpdateStore = createStore<{
  [clipId: string]: boolean;
}>({});

export function updateClipCaptionsLoadingState(clipId: string, loading: boolean) {
  clipCaptionsLoader.update(data => ({
    ...data,
    [clipId]: loading
  }));
}

/**
 * Used to render the integrated player and editor for a clip
 */
export const currentClip = createStore<CurrentClips>({});

const BACKGROUND_COLOR_DEFAULT = '#324bff';
const TEXT_COLOR_DEFAULT = '#ffffff';

function updateClipResponse(clip: Clip): Clip {
  if (!clip.asset_metadata?.layout) {
    clip.asset_metadata = {
      ...(clip.asset_metadata || {}),
      layout: 'DEFAULT',
      size: 'LANDSCAPE'
    };
  }

  const coreStore = core.getSnapshot();
  if (clip.asset_metadata && !clip.asset_metadata.magicLayout) {
    clip.asset_metadata.magicLayout = {
      backgroundColor:
        rgbaToHex(coreStore.content?.project?.theme_obj?.theme_primary_color!) || BACKGROUND_COLOR_DEFAULT,
      showSpeakerLabels: true,
      textColor: rgbaToHex(coreStore.content?.project?.theme_obj.primary_text_color!) || TEXT_COLOR_DEFAULT
    };
  } else if (clip.asset_metadata?.magicLayout) {
    clip.asset_metadata.magicLayout = {
      ...clip.asset_metadata.magicLayout,
      backgroundColor: clip.asset_metadata.magicLayout.backgroundColor || BACKGROUND_COLOR_DEFAULT,
      textColor: clip.asset_metadata.magicLayout.textColor || TEXT_COLOR_DEFAULT
    };
  }

  if (clip.asset_metadata.hide_borders === undefined) {
    clip.asset_metadata.hide_borders = !clip.asset_metadata.subtitle;
  }

  if (isAudioContent()) {
    clip.asset_metadata.layout = 'AUDIOGRAM';
  }

  clip.asset_metadata.captions = clip.asset_metadata?.captions.map((caption: any, index: number) => ({
    ...caption,
    index
  }));

  const useRemotionCaptions = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions];

  // START - CR-4289 - captions-undo-fix
  // This code is same as in sharedAPI.ts. That is required for clip handling. This is required for history handling
  // Temp code. Will be cleaned after removal of shared api
  const showCaptions = !!clip.asset_metadata.subtitle;
  if (
    clip.asset_metadata.caption_positions === undefined &&
    clip.asset_metadata.caption_styles?.placement === undefined &&
    showCaptions &&
    useRemotionCaptions &&
    clip.asset_metadata.layout !== 'AUDIOGRAM'
  ) {
    clip = {
      ...clip,
      asset_metadata: {
        ...clip.asset_metadata,
        caption_positions: {
          x: '2.5%',
          y: '82%',
          width: '95%',
          height: '18%'
        } as any
      }
    };
  }
  // END - CR-4289 - captions-undo-fix

  const layoutStatus = getLayoutAvailabilityStatus(clip.asset_metadata.layout, clip.layout_status);
  const isLayoutProcessing = layoutStatus === 'PROCESSING';

  const doesClipNeedConfigUpdate =
    !clip.asset_metadata.config || (shouldUseRemotionConfig(clip) && shouldUpdateRemotionConfig(clip));

  // ! Please check with @AshwinBhatkal before updating this variable
  clipsNeedingConfigUpdateStore.update(data => {
    data[clip.id] = doesClipNeedConfigUpdate;
    return data;
  });

  if (isLayoutProcessing !== !!clip.asset_metadata.isLayoutProcessing) {
    clipsNeedingConfigUpdateStore.update(data => {
      data[clip.id] = true;
      return data;
    });
    clip.asset_metadata.isLayoutProcessing = isLayoutProcessing;
  }

  // remotion config update will be handled as part of the clipsNeedingConfigUpdateStore action
  updateClipWithId(clip.id, clip, true, true);

  initHistory(clip.id, clip.asset_metadata);

  return clip;
}

export function initClip(id: string): Promise<Clip> {
  return loadClip(id).then((clip: Clip) => updateClipResponse(clip));
}

export const saveClipChanges: (
  clipId: string,
  shouldGenerateThumbnail?: boolean,
  shouldAddToHistory?: boolean,
  isEditedState?: boolean
) => void = debounce((clipId, shouldGenerateThumbnail = true, shouldAddToHistory = true, isEditedState = true) => {
  const clip = Object.assign({}, currentClip.getSnapshot()[clipId]);
  const clipCaptionsLoaderState = clipCaptionsLoader.getSnapshot()[clipId];
  if (isEmptyObject(clip)) return;

  if (shouldGenerateThumbnail) generateThumbnail(clipId);

  if (shouldAddToHistory) {
    updateHistory(clipId, clip.asset_metadata);
  }

  clip.asset_metadata = {
    ...clip.asset_metadata,
    is_edited: isEditedState,
    isLayoutProcessing: getLayoutAvailabilityStatus(clip.asset_metadata.layout, clip.layout_status) === 'PROCESSING'
  };

  updateClipConfig(clipId, clip)
    .then(value => {
      if (clipCaptionsLoaderState) {
        const captionsWithIndex = value.asset_metadata?.captions.map((caption: any, index: number) => ({
          ...caption,
          index
        }));

        updateClipMetadataWithId(clipId, { ...value.asset_metadata, captions: captionsWithIndex });

        updateClipCaptionsLoadingState(clipId, false);
        saveClipChanges(clipId, false, false, isEditedState);
      }
      templateLoader.set(() => false);
      updateClipWithId(clipId, { updated_at: value.updated_at }, false, true);
    })

    .catch(() => {
      showErrorToast("Updating the clip didn't go as planned. Please try refreshing the page.");
      window.analytics?.track('ContentLabEvent', {
        name: 'ContentEdited',
        isSuccess: false,
        ...getClipAnalyticsPayload(clip)
      });
    });
});

export function deleteWords(clipId: string, toDelete: DeletedRange) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;
  updateClipMetadataWithId(clipId, {
    deletes: {
      ...clipMetadata.deletes,
      [toDelete.bounds[0]]: toDelete
    }
  });

  saveClipChanges(clipId, false, true, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
  trackClipUpdate(clipId, 'DELETE_WORDS');
}

export function updateDeletes(
  clipId: string,
  deletes: ClipDeletes,
  { eventName } = { eventName: 'FULL_RECORDING_TRANSCRIPT_DELETE' }
) {
  updateClipMetadataWithId(clipId, {
    deletes
  });

  saveClipChanges(clipId, false, true, true);
  trackClipUpdate(clipId, eventName);
}

export function editWord(clipId: string, { start_time, word, end_time }: EditedWord) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;
  updateClipMetadataWithId(clipId, {
    edits: {
      ...clipMetadata.edits,
      [start_time]: { word, end_time, start_time }
    }
  });

  saveClipChanges(clipId, false, true, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
  trackClipUpdate(clipId, 'EDIT_WORD');
}

export function toggleCaptions(clipId: string, enabled: boolean) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;
  updateClipMetadataWithId(clipId, {
    subtitle: enabled,
    caption_styles: {
      scale: 1,
      font_size: DEFAULT_FONT_SIZE,
      caps: false,
      ...(clipMetadata.caption_styles || {}),
      animation: clipMetadata.caption_styles?.animation || CaptionStyle.Outline
    }
  });

  saveClipChanges(clipId, false);
  trackClipUpdate(clipId, enabled ? 'CAPTIONS_ON' : 'CAPTIONS_OFF');
}

export function toggleHideBorders(clipId: string, enabled: boolean) {
  updateClipMetadataWithId(clipId, { hide_borders: enabled });

  saveClipChanges(clipId, true);
  trackClipUpdate(clipId, enabled ? 'SHOW_BORDERS' : 'HIDE_BORDERS');
}

export function toggleSpeakerLabels(clipId: string, enabled: boolean, shouldUpdateConfig: boolean = true) {
  updateKeyInMagicLayout(clipId, 'showSpeakerLabels', enabled);

  if (shouldUpdateConfig) {
    saveClipChanges(clipId, true);
  }

  trackClipUpdate(clipId, 'TOGGLE_SPEAKER_LABELS');
}

export function selectMagicLayout(clipId: string, magicLayoutType: LayoutType) {
  const clip = currentClip.getSnapshot()[clipId];

  const layoutStatus = getLayoutAvailabilityStatus(magicLayoutType, clip.layout_status);
  updateClipMetadataWithId(clipId, {
    layout: magicLayoutType,
    size: clip.asset_metadata.size,
    isLayoutProcessing: layoutStatus === 'PROCESSING',
    ...(clip.asset_metadata.caption_styles?.scale &&
      clip.asset_metadata.caption_styles.scale > 1 && {
        caption_styles: {
          ...clip.asset_metadata.caption_styles,
          font_size: DEFAULT_FONT_SIZE,
          caps: clip.asset_metadata.caption_styles?.caps || false,
          animation: clip.asset_metadata.caption_styles?.animation || CaptionStyle.Outline,
          scale: 1
        }
      })
  });

  saveClipChanges(clipId);
  trackClipUpdate(clipId, 'SELECT_MAGIC_LAYOUT');
}

export function changeSize(clipId: string, size: SizeType) {
  updateClipMetadataWithId(clipId, { size });

  saveClipChanges(clipId);
  trackClipUpdate(clipId, 'CHANGE_SIZE');
}

export function changeCaptionsPosition({
  clipId,
  caption_positions,
  skipUpdate = false
}: {
  clipId: string;
  caption_positions: CaptionsPosition;
  skipUpdate?: boolean;
}) {
  updateClipMetadataWithId(clipId, { caption_positions });

  if (!skipUpdate) {
    saveClipChanges(clipId);
    trackClipUpdate(clipId, 'CHANGE_CAPTIONS_POSITION');
  }
}

export function updateCaptionStyles(
  clipId: string,
  captionStyles: Partial<CaptionStyles>,
  shouldAddToHistory: boolean = true
) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;

  updateClipMetadataWithId(
    clipId,
    clipMetadata.caption_styles
      ? {
          caption_styles: {
            ...clipMetadata.caption_styles,
            ...captionStyles
          }
        }
      : {
          caption_styles: {
            scale: 1,
            font_size: DEFAULT_FONT_SIZE,
            animation: CaptionStyle.Basic,
            caps: false,
            ...captionStyles
          }
        }
  );

  saveClipChanges(clipId, false, shouldAddToHistory);
  trackClipUpdate(clipId, 'CAPTION_STYLES');
}

export function updateKeyInMagicLayout(
  clipId: string,
  key: keyof Required<Clip['asset_metadata']>['magicLayout'],
  value: any
) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;

  updateClipMetadataWithId(
    clipId,
    clipMetadata.magicLayout
      ? {
          magicLayout: {
            ...clipMetadata.magicLayout,
            [key]: value
          }
        }
      : {}
  );

  saveClipChanges(clipId);
  trackClipUpdate(clipId, 'UPDATE_MAGIC_LAYOUT');
}

export function updateIntroOutro(
  clipId: string,
  key: 'intro' | 'outro',
  { url }: UpdateIntroOutroProps,
  updateHistory: boolean = true
) {
  updateClipMetadataWithId(clipId, { [key]: url });

  // Generate new thumbnail if intro/outro is removed to prevent empty thumbnail.
  // On upload, it takes time to load preview
  saveClipChanges(clipId, !url, updateHistory);
  trackClipUpdate(clipId, 'UPDATE_INTRO_OUTRO');
}

export function updateStartEndTime(clipId: string, key: 'start' | 'end', value?: number) {
  updateClipMetadataWithId(clipId, { [key]: value });

  updateClipCaptionsLoadingState(clipId, true);
  saveClipChanges(clipId, false, true, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
  trackClipUpdate(clipId, 'DURATION_CHANGED');
}

export function updateFontLocation(clipId: string, path: string) {
  updateClipMetadataWithId(clipId, { font_location: path });

  saveClipChanges(clipId, false, true);
  trackClipUpdate(clipId, 'UPDATE_FONT_LOCATION');
}

export function updateHighlightType(clipId: string, highlightType: HighlightType) {
  const clipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;

  updateClipMetadataWithId(clipId, {
    highlight_type: highlightType,
    word_highlight_color:
      highlightType === 'box' ? clipMetadata.word_highlight_color || '#000000' : clipMetadata.word_highlight_color,
    text_highlight_color:
      highlightType !== 'none'
        ? clipMetadata.text_highlight_color || clipMetadata.magicLayout?.textColor || '#ffffff'
        : clipMetadata.text_highlight_color
  });

  saveClipChanges(clipId, false, true);
  trackClipUpdate(clipId, 'UPDATE_HIGHLIGHT_TYPE');
}

export function updateHighlightColor(clipId: string, highlightColor?: string) {
  updateClipMetadataWithId(clipId, { word_highlight_color: highlightColor });

  saveClipChanges(clipId, false, true);
  trackClipUpdate(clipId, 'UPDATE_HIGHLIGHT_COLOR');
}

export function updateTextHighlightColor(clipId: string, highlightColor?: string) {
  updateClipMetadataWithId(clipId, { text_highlight_color: highlightColor });

  saveClipChanges(clipId, false, true);
  trackClipUpdate(clipId, 'UPDATE_TEXT_HIGHLIGHT_COLOR');
}

export function applyTemplate(clipId: string, template: PreviewTemplate) {
  updateClipMetadataWithId(clipId, {
    ...pick(template, propertiesToCompare as (keyof PreviewTemplate)[]),
    is_edited: false,
    template_id: template.id
  });

  const captionsPositions = template.caption_positions;
  if (captionsPositions) {
    const { x, y, width, height } = captionsPositions;

    EventBus.dispatch(CustomEvents.UpdateCaptionsPosition, {
      left: parseFloat(x as unknown as string) + '%',
      top: parseFloat(y as unknown as string) + '%',
      width: parseFloat(width as unknown as string) + '%',
      height: parseFloat(height as unknown as string) + '%'
    });
  }

  templateLoader.set(() => true);
  saveClipChanges(clipId, true, true, false);
  trackClipUpdate(clipId, 'LAYOUT_CHANGE');
}

export function getActiveCaptionsBackgroundColor(
  clipMetadata: ClipMetadata | PreviewTemplate,
  highlightType?: HighlightType,
  backgroundColor?: string
): string | undefined {
  const highlight = highlightType || clipMetadata.highlight_type;
  const bgColor = backgroundColor || clipMetadata.word_highlight_color;
  return highlight === 'box' ? bgColor : 'transparent';
}

export function getInactiveCaptionsOpacityClass(
  clipMetadata: ClipMetadata | PreviewTemplate,
  highlightType?: HighlightType
): number {
  const highlight = highlightType || clipMetadata.highlight_type;
  switch (highlight) {
    case 'text':
      return clipMetadata.caption_styles?.animation === 'Background' ? 1 : 0.5;
    default:
      return 1;
  }
}

export function updateVisibleFaceIdsInClip(clipId: string, visible_face_ids: number[]) {
  updateClipMetadataWithId(clipId, { visible_face_ids });

  saveClipChanges(clipId, false, false, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
  trackClipUpdate(clipId, 'UPDATE_VISIBLE_FACE_IDS');
}

export function updateVisibleSpeakers(clipId: string, visible_speakers: SpeakerWithDetails[]) {
  updateClipMetadataWithId(clipId, { visible_speakers });

  saveClipChanges(clipId, false, true, currentClip.getSnapshot()[clipId].asset_metadata.is_edited);
  trackClipUpdate(clipId, 'UPDATE_VISIBLE_SPEAKERS');
}

export function updateClipWithId(
  clipId: string,
  newClipData: Partial<Clip>,
  resetData?: boolean,
  skipRemotionUpdate?: boolean
) {
  currentClip.update(data => {
    const clip = data[clipId];

    const newClip = resetData
      ? newClipData
      : {
          ...clip,
          ...newClipData
        };

    return getFinalClipWithRemotionConfig(data, newClip as Clip, skipRemotionUpdate);
  });
}

export function updateClipMetadataWithId(clipId: string, newMetadata: Partial<Clip['asset_metadata']>) {
  currentClip.update(data => {
    const clip = data[clipId];

    const newClip = {
      ...clip,
      asset_metadata: {
        ...clip.asset_metadata,
        ...newMetadata
      }
    };

    return getFinalClipWithRemotionConfig(data, newClip as Clip);
  });
}

export function overwriteAllClips(newClips: Clip[]) {
  const currentClipData = currentClip.getSnapshot();

  allClips.set(() => {
    return newClips.map(clip => {
      const currentClip = currentClipData[clip.id];
      // Data that should not be overwritten on clip list reload
      if (currentClip?.asset_metadata) {
        return {
          ...clip,
          asset_metadata: {
            ...clip.asset_metadata,
            captions: currentClip.asset_metadata.captions
          }
        };
      }

      return clip;
    });
  });
}

export function appendAllClips(clips: Clip[]) {
  allClips.update(data => [...data, ...clips]);
}

export function prependAllClips(clips: Clip[]) {
  allClips.update(data => [...clips, ...data]);
}

export function clearAllClips() {
  allClips.set(() => []);
}

export function updateClipInList(updatedClip: Clip) {
  allClips.update(allClipsData => allClipsData.map(clip => (clip.id === updatedClip.id ? updatedClip : clip)));
}

export function onDuplicateClipSuccess(clip: ClipAssetResponse): Promise<void> {
  return new Promise(resolve => {
    prependAllClips([{ ...clip, isRemoved: false } as unknown as Clip]);

    setTimeout(() => {
      resolve();
    }, 200);
  });
}

export function removeClip(clipId: string): Promise<void> {
  return new Promise(resolve => {
    // Set isRemoved property to true, to animate removing, after short timeout - remove from list
    allClips.update(data =>
      data.map(clip => {
        return {
          ...clip,
          // 1st occurrence of condition check
          isRemoved: clipId === clip.asset_metadata.id || clipId === clip.id ? true : clip.isRemoved
        };
      })
    );

    setTimeout(() => {
      // 2nd occurrence of condition check
      allClips.update(data => data.filter(clip => clipId !== clip.asset_metadata.id && clipId !== clip.id));
      resolve();
    }, 500);
  });
}

export function setCanGenerateClips(canGenerate: boolean) {
  canGenerateClips.set(() => canGenerate);
}

export function trackClipUpdate(clipId: string, entityUpdated: string) {
  window.analytics?.track('ContentLabEvent', {
    name: 'ContentEdited',
    entityUpdated,
    editType: getAnalyticsClipEditType(entityUpdated),
    ...getClipAnalyticsPayload(currentClip.getSnapshot()[clipId])
  });
}

export function setClipGeneratingCount(count: number) {
  clipGeneratingCount.set(() => count);
}

export function getFinalClipWithRemotionConfig(allClips: CurrentClips, clip: Clip, skipRemotionUpdate?: boolean) {
  const clipId = clip.id;

  if (!skipRemotionUpdate && shouldUseRemotionConfig(clip)) {
    try {
      const remotionConfig = getRemotionConfig(clip as Clip);

      return {
        ...allClips,
        [clipId]: {
          ...clip,
          asset_metadata: {
            ...clip.asset_metadata,
            remotionConfig
          }
        }
      };
    } catch (e) {
      window.logger?.error('Error while updating elements timeline', e);
    }
  }

  return {
    ...allClips,
    [clipId]: clip
  };
}

const shouldUseRemotionConfig = (clip: Clip) => {
  const useClipPlayerV2 = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Clip_Player_V2];
  const useSpeakerLabelsForRecordings =
    featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings];

  const isGoldcastRecording = clip?.content?.media_source_type === 'RECORDING';
  const isUpload = clip?.content?.media_source_type === 'UPLOAD';

  const useRemotionCaptions = featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions];
  const showCaptions = !!clip.asset_metadata?.subtitle;

  return (
    (isUpload && useClipPlayerV2) ||
    (isGoldcastRecording && useSpeakerLabelsForRecordings) ||
    (showCaptions && useRemotionCaptions)
  );
};

const shouldUpdateRemotionConfig = (clip: Clip) => {
  const isRemotionConfigMissing = !clip.asset_metadata.remotionConfig;

  if (isRemotionConfigMissing) {
    return true;
  }

  const featureFlags = featureFlagStore.getSnapshot();
  const useClipPlayerV2 = featureFlags[FeatureFlagKeys.Use_CL_Clip_Player_V2];
  const useSpeakerLabelsForRecordings = featureFlags[FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings];
  const useRemotionCaptions = featureFlags[FeatureFlagKeys.Use_CL_Remotion_Captions];

  const { content, asset_metadata } = clip;
  const isGoldcastRecording = content?.media_source_type === 'RECORDING';
  const isUpload = content?.media_source_type === 'UPLOAD';
  const showSpeakerLabels = !!asset_metadata.magicLayout?.showSpeakerLabels;
  const showCaptions = !!asset_metadata?.subtitle;

  const remotionConfig = asset_metadata.remotionConfig;
  const hasNoElements = (remotionConfig?.elements || []).length === 0;
  const hasNoTracks = (remotionConfig?.tracks || []).length === 0;

  const shouldUpdateForSpeakerLabels =
    ((isUpload && useClipPlayerV2) || (isGoldcastRecording && useSpeakerLabelsForRecordings)) &&
    showSpeakerLabels &&
    hasNoElements;

  const shouldUpdateForCaptions = useRemotionCaptions && showCaptions && hasNoTracks;

  return shouldUpdateForSpeakerLabels || shouldUpdateForCaptions;
};
